<template>
  <div id="ai-services_aiu_image-nsfw">
    <service-template
      :run="run"
      :run-disabled="currentExample === null && uploadedImage === null"
      :run-success="runSuccess"
      :run-error="runError"
      :loading="loading"
      :icon="require('@/assets/images/icons/services/header/AIU-NSFW.svg')"
      type="image"
      service="aiu"
    >
      <template v-slot:header>
        {{ $t('services.imageNotSafeForWork.header') }}
      </template>
      <template v-slot:left>
        <carousel :step="1">
          <el-image
            v-for="(example, index) in examples"
            :key="index"
            :slot="`slide-${index}`"
            :class="{ 'swiper-selected': currentExample === index }"
            :src="example"
            fit="cover"
            class="example"
            @click="() => onExampleClick(index)"
          />
        </carousel>
        <upload
          :auth="isAuthenticated()"
          :file-size="2"
          :example-file="examples[currentExample]"
          :disabled="loading"
          :handle-remove="onUploadRemove"
          :handle-upload="onUploadSuccess"
        />
      </template>
      <template v-slot:right>
        <generated-result
          :step="2"
          :service-name="$t('services.imageNotSafeForWork.header')"
          :loading="loading"
          :subtitle-extra="$t(`button['Check the full list of categories']`)"
          :subtitle-extra-click="showFullListOfCategories"
        >
          <result-chart
            v-if="result"
            :chart-data="chartData"
          />
        </generated-result>
      </template>
    </service-template>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ServiceTemplate from '@/views/pages/AIServices/components/ServiceTemplate/Index.vue';
import GeneratedResult from '@/views/pages/AIServices/components/GeneratedResult/Index.vue';
import Carousel from '@/components/Carousel/Index.vue';
import Upload from '@/components/Upload/Index.vue';
import ResultChart from '@/views/components/ResultChart/Index.vue';

export default {
  name: 'ImageNotSafeForWork',
  components: {
    ResultChart,
    Upload,
    Carousel,
    GeneratedResult,
    ServiceTemplate,
  },
  data: () => ({
    loading: false,
    result: null,
    currentExample: null,
    uploadedImage: null,
  }),
  computed: {
    ...mapGetters(['isAuthenticated']),
    examples() {
      return [
        `${this.$consts.publicPath}/examples/AIU/NSFW/tennis.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/SampleVideo.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/snakes.jpeg`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/scary_face.jpeg`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/dubai.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/football.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/gym_male.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/lion.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/pants_show.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/basketball.png`,
        `${this.$consts.publicPath}/examples/AIU/NSFW/car.png`,
      ];
    },
    chartData() {
      return (Object.entries(this.result.result) || [])
        .map(([key, value]) => ({
          name: key,
          percentage: Math.round(value * 10000) / 100,
        }))
        .sort((x, y) => y.percentage - x.percentage);
    },
  },
  methods: {
    async run() {
      this.loading = true;
      return this.$api.aiu.imageNotSafeForWork.getReport(this.examples[this.currentExample]);
    },
    runSuccess(result) {
      this.loading = false;
      this.result = result;
    },
    runError() {
      this.loading = false;
    },
    onExampleClick(index) {
      if (this.loading) {
        return;
      }

      this.currentExample = index;
      this.uploadedImage = null;
      this.result = null;
    },
    onUploadRemove() {
      this.result = null;
      this.uploadedImage = null;
      this.currentExample = null;
    },
    onUploadSuccess(file) {
      this.uploadedImage = file;
    },
    showFullListOfCategories() {
      const routeData = this.$router.resolve({ path: '/labels/not-safe-for-work' });
      window.open(routeData.href, '_blank');
    },
  },
};
</script>

<style lang="scss" scoped>
#ai-services_aiu_image-nsfw {
  background: $background-secondary;

  .example {
    height: 100%;
    cursor: pointer;
  }
}
</style>
